export const MORE_INFORMATION_QUERY = `MoreInformation {  
    moreInformations(sort: "id:ASC"){
      data {
        id
        attributes {
            more_information{
              label
              description
            }
        }
      }
    }
  }`;
