import { appConfigService } from "@/providers/services/app/app-config";
import axios from "axios";

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
export class Http {
  private static INSTANCE = new Http();

  static get instance() {
    return this.INSTANCE;
  }

  public get(url: string, headers?: any, params?: any) {
    const options = {
      url: this.formURL(url),
      method: "GET",
      headers,
      params,
    };
    return axios(options);
  }

  public post(
    url: string,
    headers?: any,
    data?: any,
    isFile = false,
    onUploadProgress?: any
  ) {
    const options: any = {
      url: this.formURL(url),
      method: "POST",
      headers,
      data: isFile ? data : data ? JSON.stringify(data) : "{}",
    };
    if (isFile) {
      options["onUploadProgress"] = onUploadProgress;
    }
    return axios(options);
  }

  public put(url: string, headers?: any, data?: any) {
    const options = {
      url: this.formURL(url),
      method: "PUT",
      headers,
      data,
    };
    return axios(options);
  }

  public delete(url: string, headers?: any, data?: any) {
    const options = {
      url: this.formURL(url),
      method: "DELETE",
      headers,
      data,
    };
    return axios(options);
  }

  private formURL(url: string) {
    const apiUrl = appConfigService.getApiUrl();
    if (apiUrl && !url.includes("http")) {
      url = `${apiUrl}/${url}`;
    }
    return url;
  }

  private formStrapiURL(url: string) {
    const apiUrl = appConfigService.getStrapiApiUrl();
    if (apiUrl && !url.includes("http")) {
      url = `${apiUrl}/${url}`;
    }
    return url;
  }

  public strapiPost(url: string, data?: any) {
    const options: any = {
      url: this.formStrapiURL(url),
      method: "POST",
      data,
    };
    return axios(options);
  }

  public strapiGet(url: string) {
    const options: any = {
      url: this.formStrapiURL(url),
      method: "GET",
    };
    return axios(options);
  }

  public getBasicHeaders(token: string) {
    return {
      "Content-Type": "application/json",
      Authorization: `Basic ${window.btoa(token)}`,
    };
  }

  public getTokenHeaders(token?: string) {
    const access_token = token ? token : localStorage.getItem("access_token");
    return {
      "Content-Type": "application/json",
      Authorization: `Token ${access_token}`,
    };
  }
}

export const http = Http.instance;
