export const PEER_FRONT_FACING_LISTING_QUERY = `Peerfrontfacinglistings {  
    peerFrontFacingListings{
      data {
        id
        attributes {
            label
            title
            description
        }
      }
    }
  }`;
