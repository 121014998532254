export const HELP_info_QUERY = `HelpInformations {  
    helpInformations (pagination: { page: 1, pageSize: 100} )  {
        data {
            id
            attributes {
                seq_id
                title
                url
                state
                program
                description
            }
        }
    }
}`;
