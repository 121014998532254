export const HEADER_QUERY = `HeaderMenus {  
    headerMenus(sort: "sort_id:ASC"){
      data {
        id
        attributes {
              sort_id
              menus
        }
      }
    }
  }`;

export const HEADER_METADATA_QUERY = `HeaderMetadata {  
  headerMetadatas(sort: "id:ASC"){
      data {
        id
        attributes {
          label
          url
          image {
            data {
              attributes {
                url
              }
            }
          }
          button {
                url
                name
          }
        }
      }
    }
  }`;
