import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { FOOTER_QUERY } from "@/graphql/footer-query";
import { attributeSubmenu, FooterData, menu } from "./footer.interface";
import { appConfigService } from "@/providers/services/app/app-config";
import { utils } from "@/utils/utils";

@Component({})
export default class CadreFooterComponent extends Vue {
  public footerData: FooterData[] = [];
  public logo = {
    name: "",
    url: "",
  };
  public TNCertifiedLogo = {
    name: "",
    url: "",
  };

  public footerSubmenus: menu[] = [];
  public isMobileView = false;

  public async created() {
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    await this.$apollo
      .query({
        query: gql`query ${FOOTER_QUERY}`,
      })
      .then((result) => {
        this.footerData = result.data.footers.data;
        this.footerSubmenus = this.footerData[0].attributes.navigations;
        const tempPickList = JSON.parse(JSON.stringify(this.footerSubmenus)); // It uses of fix the "Cannot assign to read only property 'attributes' of object";
        tempPickList.map((data: menu, index: number) => {
          if (data !== null && data) {
            const tempPickSubList = JSON.parse(
              JSON.stringify(data.menus.subMenus)
            );
            tempPickSubList.map((subMenus: attributeSubmenu) => {
              if (subMenus.slug === "cyapss") {
                subMenus.url = appConfigService.getCYAPSSUrl();
              } else if (subMenus.slug === "cprs") {
                subMenus.url = appConfigService.getCPRSUrl();
              } else if (subMenus.slug === "cfss") {
                subMenus.url = appConfigService.getCFSSUrl();
              }
              tempPickList[index].menus.subMenus = tempPickSubList;
            });
          }
        });
        this.footerSubmenus = tempPickList;
        this.logo = {
          name: this.footerData[0].attributes.footer_logo.logo.data.attributes
            .name,
          url: this.footerData[0].attributes.footer_logo.logo.data.attributes
            .url,
        };
        this.TNCertifiedLogo = {
          name: this.footerData[0].attributes.footer_logo.TN_certified_logo.data
            .attributes.name,
          url: this.footerData[0].attributes.footer_logo.TN_certified_logo.data
            .attributes.url,
        };
      });
  }

  public navigator(url: string, slug: string) {
    if (this.$router.currentRoute.path === url) {
      if (this.isMobileView) {
        // need to implement for scroll
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      const programs = ["cyapss", "cprs", "cfss"];
      if (programs.includes(slug)) {
        window.location.href = url;
      } else {
        url ? this.$router.push(url) : "";
      }
    }
  }

  public scrollToElement() {
    const el = document.getElementsByClassName("aboutus-container")[0];
    if (el) {
      if (this.isMobileView) {
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        const yOffset = 167;
        const scrollTop =
          el.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
      }
    }
  }
}
