import {
  createHttpLink,
  InMemoryCache,
  ApolloClient,
} from "@apollo/client/core";
import { appConfigService } from "./providers/services/app/app-config";

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: appConfigService.getStrapiWithGraphqlApiUrl(),
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

export default apolloClient;
