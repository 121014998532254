import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { MORE_INFORMATION_QUERY } from "@/graphql/more-information-query";
import { InformationlData } from "./information.interface";

@Component({})
export default class MoreInformationComponent extends Vue {
  public informationlData: InformationlData[] = [];
  public currentRoute = "";

  public async created() {
    this.currentRoute = this.$route.path.split("/")[1];
    await this.$apollo
      .query({
        query: gql`query ${MORE_INFORMATION_QUERY}`,
      })
      .then((result) => {
        this.informationlData = result.data.moreInformations.data;
      });
    this.$nextTick(() => {
      const getDescriptionElement =
        document.getElementsByClassName("description")[0];
      const getImageFigureElement =
        getDescriptionElement.getElementsByClassName("image");
      for (let i = 0; i < getImageFigureElement.length; i++) {
        getImageFigureElement[i].classList.add(`image-${i + 1}`);
      }
    });
  }
}
