import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import gql from "graphql-tag";
import { commonService } from "@/providers/services/common-service";
import { AgenciesView } from "@/models/agency-view/agency-view.interface";
import AgenciesServiceListComponent from "../agency-service-lists/agency-service-lists.component.vue";
import { AGENCY_SERVICE_LIST_QUERY } from "@/graphql/agency-service-list-query";

@Component({
  name: "agency-view",
  components: {
    AppLoaderComponent,
    AgenciesServiceListComponent,
  },
})
export default class AgenciesViewComponent extends Vue {
  public isLoading = true;
  public agencyView: AgenciesView = {} as AgenciesView;
  public serviceList: any = [];

  public async created() {
    const id = this.$route.path.split("/")[3];
    commonService.findOneAgency(id).then((result: any) => {
      this.agencyView = result.data.data;
      this.isLoading = false;
    });
    await this.$apollo
      .query({
        query: gql`query ${AGENCY_SERVICE_LIST_QUERY}`,
      })
      .then((result) => {
        this.serviceList = result?.data?.agencyServiceLists?.data;
      });
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public back() {
    this.$router.go(-1);
  }
}
