export const AGENCY_SUBMISSION_QUERY = `AgencySubmissions {  
    agencySubmissions(sort: "id:ASC"){
        data{
            id
            attributes{
                title
                content
                description
                form
            }
        }
    }
}`;
