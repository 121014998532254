export const AGENCY_SERVICE_LIST_QUERY = `AgencyServiceLists {  
    agencyServiceLists {
      data {
        id
        attributes {
            name
            ref_id
        }
      }
    }
  }`;
