import { Component, Vue } from "vue-property-decorator";
import CadreHeader from "../header/cadre-header.vue";
import CadreFooterComponent from "../cadre-footer/cadre-footer.component.vue";

@Component({
  components: {
    CadreHeader,
    CadreFooterComponent,
  },
})
export default class BaseLayout extends Vue {
  public headerHeight = 0;
  mounted() {
    const getHeaderElement = document.getElementById("header");
    this.headerHeight = getHeaderElement?.scrollHeight as number;
  }
}
