import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { fileValidation } from "@/models/common-interface/common.interface";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";

@Component({
  name: "b-form-upload-file",
  components: {
    PreviewPopupComponent,
  },
})
export default class FileUploadComponent extends Vue {
  @Prop()
  public isInvalid!: boolean;
  @Prop()
  public fileValidation!: fileValidation;
  @Prop()
  public keyName!: string;
  @Prop()
  public checkFileValidation!: boolean;

  @Inject("$validator") public $validator: any;

  public file: any;
  public files: any[] = [];
  public uploadMsgShow = false;
  public previewData: any[] = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public showPreviewPopup = false;
  public hideAddMoreButton = true;
  public isSingleFileUpload = true;
  public acceptFiles = "image/png, image/jpg, image/jpeg";

  public handleFileSelect(evt: any) {
    if (evt.target.files) {
      Array.from(evt.target.files).forEach((element: any) => {
        this.files.push(element);
      });
      evt.target.value = null;
      this.$emit("input", this.files);
    }
  }

  public previewFile(index: number) {
    this.file = this.files[index];
    if (this.file) {
      if (this.file.type.split("/")[0] === "application") {
        const file = new Blob([this.file], { type: this.file.type });
        const fileURL = URL.createObjectURL(file);
        this.previewData = [
          {
            url: fileURL,
            name: this.file.name,
            type: this.file.type,
          },
        ];
        this.showPreviewPopup = true;
      } else {
        const fileURL = URL.createObjectURL(this.file);
        const img = new Image();
        img.src = fileURL;
        this.previewData = [
          {
            width: img.width,
            height: img.height,
            url: fileURL,
            name: this.file.name,
            type: this.file.type,
          },
        ];
        this.showPreviewPopup = true;
      }
    }
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }

  public deleteFile(file: any, index: number) {
    if (this.files.length) {
      this.files.splice(index, 1);
      if (file instanceof File) {
        this.uploadMsgShow = false;
      }
      this.$emit("input", this.files);
    }
  }

  @Watch("checkFileValidation")
  public validation() {
    const checkFileValid = this.files.some((value) => {
      return value instanceof File;
    });
    if (checkFileValid) {
      this.uploadMsgShow = true;
      this.$emit("isNotUploadedKeyName", this.keyName);
    } else {
      this.$emit("isUploadedKeyName", this.keyName);
    }

    this.$validator.validateAll().then((validate: any) => {
      if (validate) {
        this.$emit("isFileValid", true);
      }
    });
  }
}
