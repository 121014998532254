export const CADRE_PROGRAM_QUERY = `CadrePrograms {  
  cadrePrograms{
      data {
        id
        attributes {
              label
              title
              description
              button {
                label
              }
              image {
                data {
                  attributes {
                        name
                        url
                        formats
                    }
                }
              }
        }
      }
    }
  }`;
