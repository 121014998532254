import { Component, Vue } from "vue-property-decorator";
import MoreInformationComponent from "@/components/more-information/more-information.component.vue";
import { HELP_info_QUERY } from "@/graphql/help-page-query";
import { gql } from "graphql-tag";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";

@Component({
  components: {
    MoreInformationComponent,
    AppLoaderComponent,
  },
})
export default class HelpComponent extends Vue {
  public helpData: any;
  public tutorials: any = [];
  public headerData = [
    { label: "CPRS" },
    { label: "CFSS" },
    { label: "CYAPSS" },
  ];
  public currentTab = "CPRS";
  public isLoading = false;
  public isIframeUrl = false;
  public iframeTutorialUrl: any;

  public async created() {
    this.isLoading = true;
    this.$apollo
      .query({
        query: gql`query ${HELP_info_QUERY}`,
      })
      .then((result) => {
        this.helpData = result.data.helpInformations.data;
        this.isLoading = false;
        this.onEmbbedUrl();
      });
  }
  public onNavigation(content: any) {
    this.isLoading = true;
    this.currentTab = content;
    setTimeout(() => {
      this.onEmbbedUrl();
    }, 1000);
  }
  public onEmbbedUrl() {
    this.tutorials = [];
    this.helpData.forEach((item: any) => {
      if (item.attributes.program === this.currentTab) {
        const id = item.attributes.url.split("/")[3];
        const embbedUrl = `https://www.youtube.com/embed/${id}`;
        const tutorial = {
          title: item.attributes.title,
          url: embbedUrl,
          seq_id: item.attributes.seq_id,
          program: item.attributes.program,
          description: item.attributes.description,
        };
        this.tutorials.push(tutorial);
      }
    });
    this.isLoading = false;
  }
  public openvideoPopup(content: any) {
    this.isIframeUrl = true;
    this.iframeTutorialUrl = content.url;
    this.$nextTick(() => {
      this.$bvModal.show("player");
    });
  }
  public closePopup() {
    this.$bvModal.hide("player");
    this.iframeTutorialUrl = null;
    this.isIframeUrl = false;
  }
}
