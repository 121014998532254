import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import gql from "graphql-tag";
import { AGENCIES_LISTING_QUERY } from "@/graphql/agencies-listing-query";
import { AgenciesList } from "@/models/agency-listings/agency-listings.interface";
import AgenciesDescriptionComponent from "../agency-description/agency-description.component.vue";

@Component({
  name: "agency-listing",
  components: {
    AppLoaderComponent,
    AgenciesDescriptionComponent,
  },
})
export default class AgenciesListingComponent extends Vue {
  public isLoading = true;
  public agencyList: AgenciesList[] = [];
  public limit = 9;
  public page = 1;
  public totalItem = 0;

  public async created() {
    await this.getAgencyList();
  }

  public async getAgencyList() {
    this.isLoading = true;
    await this.$apollo
      .query({
        query: gql`query 
                      Agency {  
                        agencies (filters: {status: {eq: "Approved"}} sort: ["createdAt:desc"], pagination: { page: ${this.page}, pageSize: ${this.limit} }) { 
                          ${AGENCIES_LISTING_QUERY} 
                        } 
                      }`,
      })
      .then((result) => {
        this.totalItem = result.data.agencies.meta.pagination.total;
        this.agencyList = result.data.agencies.data;
        this.isLoading = false;
      });
  }

  public agencyView(agency: AgenciesList) {
    this.$router.push(`/agency/view/${agency.id}`);
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }

  public loadContent(page: number) {
    this.page = page;
    this.getAgencyList();
  }
}
