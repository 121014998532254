export const AGENCIES_LISTING_QUERY = `
 data {
        id
        attributes {
          organization_Info {
            resource_name
            summary
            website_url
            location
            logo {
              data {
                attributes {
                  url
                }
              }
            }
            featured {
              data {
                attributes {
                  url
                }
              }
            }
          }
          contact_info {
            firstName
            lastName
            phone
            emailAddress
          }
          services {
            services
          }
          status
        }
      }
      meta {
        pagination {
          total
          pageCount
          page
        }
      }
      `;
