import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ChipSelect extends Vue {
  @Prop()
  public chipList!: any[];
  @Prop()
  public hideAdd!: boolean;

  public selectedValues: any[] = [];
  public selectedIdx: any[] = [];

  public chipSelect(list: any, index: number) {
    const findIdx = this.selectedValues.findIndex((data) => {
      return data.ref_id === list.ref_id;
    });
    if (findIdx > -1) {
      this.selectedValues.splice(findIdx, 1);
      this.selectedIdx.splice(findIdx, 1);
    } else {
      if (this.selectedValues.length) {
        this.selectedValues.push(list);
        this.selectedIdx.push(index);
      } else {
        this.selectedValues = [list];
        this.selectedIdx = [index];
      }
    }
    this.$emit("input", this.selectedValues);
  }
}
