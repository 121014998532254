import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { HEADER_METADATA_QUERY, HEADER_QUERY } from "@/graphql/header-query";
import {
  HeaderLogo,
  HeaderMenu,
  HeaderButton,
  attributeSubmenu,
  AttributeMenu,
} from "./header.interface";
import { appConfigService } from "@/providers/services/app/app-config";
import { utils } from "@/utils/utils";

@Component({})
export default class CadreHeader extends Vue {
  public tooglePrograms = false;
  public toogleAgencies = false;
  public tooglePeerSpecialists = false;
  public headerDatas: HeaderMenu[] = [];
  public HeaderLogoData: HeaderLogo[] = [];
  public headerLogoSrc = "";
  public hoverMenuSlug = "";
  public headerMetadata: HeaderButton = {
    name: "",
    url: "",
  };
  public isScroll = false;
  public isMobileView = false;
  public isShowMenu = false;
  public isClickMenu = true;
  public headerSubmenus: any[] = [];
  public isShowSideBar = false;

  constructor() {
    super();
  }
  public async created() {
    if (utils.checkIsMobileView() || utils.checkIsTabView()) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    await this.$apollo
      .query({
        query: gql`query ${HEADER_QUERY}`,
      })
      .then((result) => {
        this.headerDatas = result.data.headerMenus.data;
        this.headerSubmenus = this.headerDatas.map((subMenus: HeaderMenu) => {
          if (subMenus.attributes.menus.subMenus) {
            return subMenus.attributes.menus.subMenus;
          }
        });
        const tempPickList = JSON.parse(JSON.stringify(this.headerSubmenus)); // It uses of fix the "Cannot assign to read only property 'attributes' of object";
        tempPickList.map((data: AttributeMenu, index: number) => {
          if (data !== null && data) {
            const tempPickSubList = JSON.parse(JSON.stringify(data));
            tempPickSubList.map((subList: attributeSubmenu) => {
              if (subList.slug === "cyapss") {
                subList.url = appConfigService.getCYAPSSUrl();
              } else if (subList.slug === "cprs") {
                subList.url = appConfigService.getCPRSUrl();
              } else if (subList.slug === "cfss") {
                subList.url = appConfigService.getCFSSUrl();
              }
              tempPickList[index] = tempPickSubList;
            });
          }
        });
        this.headerSubmenus = tempPickList;
      });
    this.$apollo
      .query({
        query: gql`query ${HEADER_METADATA_QUERY}`,
      })
      .then((result) => {
        this.HeaderLogoData =
          result.data.headerMetadatas.data[0].attributes.image.data.attributes;
        this.headerLogoSrc =
          result.data.headerMetadatas.data[0].attributes.image.data.attributes.url;
        this.headerMetadata =
          result.data.headerMetadatas.data[0].attributes.button;
      });
  }

  public mounted() {
    let navbar: any;
    this.$nextTick().then(() => {
      window.document.onscroll = () => {
        if (this.isMobileView) {
          navbar = document.getElementsByClassName("mobile-main-container")[0];
        } else {
          navbar = document.getElementById("main-container");
        }
        if (navbar) {
          if (window.scrollY > 55) {
            this.isScroll = true;
            this.isClickMenu = false;
            this.hoverMenuSlug = "";
          } else if (window.scrollY < 55) {
            this.isScroll = false;
          }
        }
      };
    });
  }

  public outSideClick(event: any) {
    const getListsDiv = document.getElementsByClassName("menu-items")[0];
    if (getListsDiv && !getListsDiv.contains(event.target)) {
      this.isClickMenu = false;
      this.hoverMenuSlug = "";
      if (this.isMobileView) {
        this.isShowMenu = false;
      }
    }
  }

  public updated() {
    window.document.onclick = (event) => {
      this.outSideClick(event);
    };
  }

  public navigator(url: string, menuSlug: string, menuType = "") {
    if (menuType === "main-menu") {
      if (this.$router.currentRoute.path === url) {
        if (this.isMobileView) {
          this.isShowSideBar = false;
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        url ? this.$router.push(url) : "";
      }
      if (this.hoverMenuSlug === menuSlug) {
        this.isClickMenu = false;
        this.hoverMenuSlug = "";
      } else {
        this.isClickMenu = true;
        this.hoverMenuSlug = menuSlug;
      }
    } else if (menuType === "sub-menu") {
      this.isClickMenu = false;
      if (this.$router.currentRoute.path === url) {
        if (this.isMobileView) {
          //need to implement for scroll
          this.isShowSideBar = false;
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        const programs = ["cyapss", "cprs", "cfss"];
        if (programs.includes(menuSlug)) {
          window.location.href = url;
        } else {
          this.$router.push(url);
        }
      }
    }
  }

  public getElement(hideSideBar: boolean) {
    this.isClickMenu = false;
    this.hoverMenuSlug = "";
    if (this.$router.currentRoute.path !== "/home") {
      this.$router.push("/home");
      setTimeout(() => {
        this.scrollToElement(
          document.getElementsByClassName("our-programs-container")[0],
          hideSideBar
        );
      }, 300);
    } else {
      this.scrollToElement(
        document.getElementsByClassName("our-programs-container")[0],
        hideSideBar
      );
    }
  }

  public scrollToElement(element: Element, hideSideBar: boolean) {
    const el = element;
    const yOffset = 167;
    if (el) {
      if (this.isMobileView) {
        el.scrollIntoView({ behavior: "smooth" });
        this.isShowSideBar = hideSideBar;
      } else {
        const scrollTop =
          el.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
      }
    }
  }
}
