import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { AGENCY_DESCRIPTION_QUERY } from "@/graphql/agency-description-query";
import { agencyDescriptionsData } from "./agency-description.interface";

@Component({})
export default class AgenciesDescriptionComponent extends Vue {
  public currentRoute = "";
  public agencyDescriptionsData: agencyDescriptionsData[] = [];
  public async created() {
    this.currentRoute = this.$route.path.split("/")[1];
    await this.$apollo
      .query({
        query: gql`query ${AGENCY_DESCRIPTION_QUERY}`,
      })
      .then((result) => {
        this.agencyDescriptionsData = result.data.agencyDescriptions.data;
      });
  }
}
