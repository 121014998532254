export const AGENCY_FORM_QUERY = `AgencyForms {  
    agencyForms {
      data {
        id
        attributes {
            heading
            formFields
        }
      }
    }
  }`;
