var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"agency-form"},[_c('div',{staticClass:"agency-form-main-container"},[(_vm.agenciesForm.length)?[_c('h5',{staticClass:"heading",domProps:{"innerHTML":_vm._s(_vm.agenciesForm[0].attributes.heading)}}),_c('div',{staticClass:"agency-form-container"},_vm._l((_vm.agenciesForm[0].attributes.formFields
            .fields),function(fields,index){return _c('div',{key:index,staticClass:"fields-container"},_vm._l((fields.components),function(components,indexi){return _c('div',{key:indexi,staticClass:"fields-components"},[_c('label',{staticClass:"form-name"},[_c('span',{domProps:{"innerHTML":_vm._s(components.formName)}}),(
                  _vm.checkValidForm &&
                  !_vm.isServiceValid &&
                  components.formName === 'Service'
                )?_c('p',{staticClass:"is-invalid service-label"},[_vm._v(" (Please add the service) ")]):_vm._e()]),(components.inputs)?_vm._l((components.inputs),function(formInputs,indexj){return _c('div',{key:indexj,staticClass:"components-input"},_vm._l((formInputs),function(inputs,indexk){return _c('div',{key:indexk,staticClass:"fields"},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(inputs.label)+" "),(
                        inputs.label &&
                        (inputs?.fileValid?.veeValidate === 'required' ||
                          inputs?.validate?.required === true)
                      )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c(inputs.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(inputs?.validate?.veeValidate),expression:"inputs?.validate?.veeValidate"}],tag:"component",class:[inputs.class],attrs:{"autocomplete":"off","fileValidation":inputs?.fileValid,"keyName":inputs.name},on:{"serviceId":function($event){return _vm.selectedService($event)}},nativeOn:{"keypress":function($event){_vm.eventBind[inputs?.propBind?.eventName]
                        ? _vm.fieldValidation(
                            $event,
                            inputs?.propBind?.eventName,
                            inputs?.propBind.type,
                            inputs?.validate?.maxLength
                          )
                        : _vm.emptyEvent}},model:{value:(_vm.agencyPayload[components.formSlug][inputs.name]),callback:function ($$v) {_vm.$set(_vm.agencyPayload[components.formSlug], inputs.name, $$v)},expression:"agencyPayload[components.formSlug][inputs.name]"}},'component',{
                      ...inputs.propBind,
                      ...inputs.validate,
                    },false)),(
                      inputs.label == 'Upload logo file' ||
                      inputs.label == 'Upload featured images'
                    )?_c('p',{staticClass:"pt-1 note-text"},[_vm._v(" Note: "+_vm._s(inputs.label == "Upload logo file" ? "Logo" : "Image")+" size should be greater than 200px * 180px ")]):_vm._e(),(
                      _vm.checkValidForm && _vm.errors.has(inputs?.validate?.name)
                    )?_c('span',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(inputs.propBind?.type === "email" ? _vm.errors.first(inputs?.validate?.name) : "This field is required"))]):_vm._e()],1)}),0)}):_vm._e(),(components.injectComponent)?[_c(components.injectComponent,{tag:"component",attrs:{"checkServiceValid":_vm.checkServiceValid},on:{"serviceId":function($event){return _vm.selectedService($event)},"isServiceValid":function($event){return _vm.serviceValidation($event)}}})]:_vm._e()],2)}),0)}),0),_c('button',{staticClass:"submit-btn",on:{"click":_vm.submit}},[_vm._v("Submit")]),_c('b-modal',{attrs:{"id":"error-modal-center","centered":"","hide-footer":"","hide-header":""}},[_c('p',{staticClass:"close",on:{"click":function($event){return _vm.closePopup()}}},[_c('b-icon',{staticClass:"close-icon",staticStyle:{"color":"black"},attrs:{"icon":"x-lg"}})],1),_c('div',{staticClass:"popup-body-content"},[_c('p',{staticClass:"message"},[_vm._v(_vm._s(_vm.errorMessage))])])])]:_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }