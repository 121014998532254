import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "b-form-textarea-input",
})
export default class InputTextAreaComponent extends Vue {
  @Prop()
  public value!: string;

  public text!: string;

  public created() {
    this.text = this.value;
  }
  onChangeInput() {
    this.$emit("input", this.text);
  }
}
