import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CadreProgramData } from "./cadre-program.interface";
import { CADRE_PROGRAM_QUERY } from "@/graphql/cadre-program-query";

@Component
export default class CadreProgramComponent extends Vue {
  public cadreProgramData: CadreProgramData[] = [];
  public cadreProgramImage = { name: "", url: "" };

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${CADRE_PROGRAM_QUERY}`,
      })
      .then((result) => {
        this.cadreProgramData = result.data.cadrePrograms.data;
        this.cadreProgramImage = {
          name: this.cadreProgramData[0].attributes.image.data.attributes.name,
          url: this.cadreProgramData[0].attributes.image.data.attributes.url,
        };
      });
  }
}
