import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { MAIN_PAGE_CAROUSEL_QUERY } from "@/graphql/main-page-carousel-query";
import { CarousalData, carousalImage } from "./carousel.interface";
import { utils } from "@/utils/utils";

@Component
export default class CarouselComponent extends Vue {
  public carousels: CarousalData[] = [];
  public carousalImg: carousalImage[] = [];
  public carousalTitle = { title: "", subTitle: "" };
  public carousalButton = { label: "", url: "" };
  public slideInterval = 3000;

  constructor() {
    super();
  }

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${MAIN_PAGE_CAROUSEL_QUERY}`,
      })
      .then((result) => {
        this.carousels = result.data.banners?.data;
        for (
          let i = 0;
          i < this.carousels[0].attributes.carousel_images.length;
          i++
        ) {
          if (this.carousalImg.length) {
            this.carousalImg.push({
              name: this.carousels[0].attributes.carousel_images[i].image.data
                .attributes.name,
              url: this.carousels[0].attributes.carousel_images[i].image.data
                .attributes.url,
              description:
                this.carousels[0].attributes.carousel_images[i].description,
            });
          } else {
            this.carousalImg = [
              {
                name: this.carousels[0].attributes.carousel_images[i].image.data
                  .attributes.name,
                url: this.carousels[0].attributes.carousel_images[i].image.data
                  .attributes.url,
                description:
                  this.carousels[0].attributes.carousel_images[i].description,
              },
            ];
          }
        }
      });
  }

  public hoverBtn(event: boolean) {
    if (event) {
      this.slideInterval = 0;
    } else {
      this.slideInterval = 3000;
    }
  }

  public scrollToElement() {
    const el = document.getElementsByClassName("our-programs-container")[0];
    if (el) {
      if (utils.checkIsMobileView() || utils.checkIsTabView()) {
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        const yOffset = 167;
        const scrollTop =
          el.getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
      }
    }
  }
}
