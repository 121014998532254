export const OUR_PROGRAMS_QUERY = `OurPrograms {  
    ourPrograms {
      data {
        id
        attributes {
              label
              title
              our_programs_cards {
                parent_slug,
                label,
                color,
                description
                logo_image {
                    data {
                        attributes {
                            name
                            url
                        }
                    }
                }
                button{
                    label
                    url
                    slug
                }
              }
        }
      }
    }
  }`;
