export const ABOUT_US_QUERY = `AboutUs {  
    aboutUses{
      data {
        id
        attributes {
          label
          title
          description
          description2
          image {
            data {
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
  }`;
