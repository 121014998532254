import gql from "graphql-tag";
import { Component, Vue } from "vue-property-decorator";
import { AGENCY_SUBMISSION_QUERY } from "@/graphql/agency-submitted-query";
import {
  submittedContent,
  AgencySubmissionInterface,
} from "@/models/agency-submission/agency-submission.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";

@Component({
  name: "agency-submission",
  components: {
    AppLoaderComponent,
  },
})
export default class AgencySubmissionComponent extends Vue {
  public applicationSubName: any;
  public applicationId: any;
  public SuccessfulMessage: submittedContent = {} as submittedContent;
  public messageContent = "";
  public isLoading = true;
  public applicationSubmittedData: AgencySubmissionInterface =
    {} as AgencySubmissionInterface;

  public async created() {
    this.applicationSubName = this.$route.query.subTitle;
    this.applicationId = this.$route.query.applicationId;
    await this.$apollo
      .query({
        query: gql`query ${AGENCY_SUBMISSION_QUERY}`,
      })
      .then((result) => {
        this.isLoading = false;
        this.applicationSubmittedData = result;
        const currentApplicationSubmitted =
          this.applicationSubmittedData.data.agencySubmissions.data.find(
            (result: any) => {
              if (result.attributes.form === this.applicationSubName) {
                return result;
              }
            }
          );
        if (currentApplicationSubmitted?.attributes) {
          this.SuccessfulMessage = currentApplicationSubmitted?.attributes;
          this.messageContent =
            currentApplicationSubmitted?.attributes.content.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            );
        }
      });
  }
}
