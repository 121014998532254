import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { OUR_PROGRAMS_QUERY } from "@/graphql/our-programs-query";
import { CardsData, OurProgramsData } from "./our-programs.interface";
@Component
export default class OurProgramsComponent extends Vue {
  public ourProgramsData: OurProgramsData[] = [];
  public programCards: CardsData[] = [];

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${OUR_PROGRAMS_QUERY}`,
      })
      .then((result) => {
        this.ourProgramsData = result.data.ourPrograms.data;
        this.programCards =
          this.ourProgramsData[0].attributes.our_programs_cards;
        const tempPickList = JSON.parse(JSON.stringify(this.programCards)); // It uses of fix the "Cannot assign to read only property 'attributes' of object";
        tempPickList.map((data: CardsData) => {
          if (!data.parent_slug) {
            if (data.button.url.includes("cyapss")) {
              data.parent_slug = "cyapss";
            } else if (data.button.url.includes("cprs")) {
              data.parent_slug = "cprs";
            } else if (data.button.url.includes("cfss")) {
              data.parent_slug = "cfss";
            }
          }
        });
        this.programCards = tempPickList;
      });
  }
}
