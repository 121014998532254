import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { PEER_SPECIALISTS_QUERY } from "@/graphql/peer-specialists-query";
import { PeerSpecialistsData } from "@/models/peer-specialist.interface.ts/peer-specialists.interface";

@Component({})
export default class PeerSpecialistsTestimonailComponent extends Vue {
  public peerSpecialitsData: PeerSpecialistsData[] = [];
  public slide = 0;

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${PEER_SPECIALISTS_QUERY}`,
      })
      .then((result) => {
        this.peerSpecialitsData = result.data.peerSpecialists.data;
      });
  }

  public carousalCardChange(index: number) {
    this.slide = index;
  }
}
