import { Component, Vue } from "vue-property-decorator";
import { agencyForm } from "./agencies-register.interface";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import gql from "graphql-tag";
import { AGENCY_FORM_QUERY } from "@/graphql/agency-form-query";
import { utils } from "@/utils/utils";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputTextAreaComponent from "../input-textarea/input-textarea.component.vue";
import AgenciesServiceListComponent from "../agency-service-lists/agency-service-lists.component.vue";
import { commonService } from "@/providers/services/common-service";

@Component({
  name: "agency-register",
  components: {
    AppLoaderComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-textarea-input": InputTextAreaComponent,
    "b-form-agency-service-list": AgenciesServiceListComponent,
  },
})
export default class AgenciesRegisterComponent extends Vue {
  public agenciesForm: agencyForm[] = [];
  public isLoading = true;
  public checkValidForm = false;
  public isServiceValid = false;
  public checkServiceValid = true;
  public errorMessage = "";
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
  };
  public agencyPayload: any = {
    organization_Info: {
      resource_name: "",
      summary: "",
      website_url: "",
      location: "",
      logo: [],
      featured: [],
    },
    contact_info: {
      firstName: "",
      lastName: "",
      phone: "",
      emailAddress: "",
    },
    services: {
      services: "",
    },
  };

  public selectedServiceRedfId: number[] = [];

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${AGENCY_FORM_QUERY}`,
      })
      .then((result) => {
        this.isLoading = false;
        this.agenciesForm = result.data.agencyForms.data;
      });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public emptyEvent() {
    return;
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public selectedService(event: number[]) {
    this.selectedServiceRedfId = event;
  }

  public serviceValidation(event: boolean) {
    this.isServiceValid = event;
  }

  public submit() {
    this.checkServiceValid = !this.checkServiceValid;
    this.checkValidForm = true;
    this.$validator.validateAll().then((validate: any) => {
      if (validate && this.isServiceValid) {
        this.isLoading = true;
        const serviceJoin = this.selectedServiceRedfId.join(",");
        this.agencyPayload.services.services = serviceJoin;
        this.agencyPayload.Status = "InProgress";
        commonService
          .createAgency(this.agencyPayload)
          .then((result) => {
            this.isLoading = false;
            const someData = {
              SubTitle: "Agency Registration",
            };
            this.$router.push({
              path: "/agency/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            window.scrollTo(0, 0);
            this.isLoading = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
            this.$bvModal.show("error-modal-center");
            setTimeout(() => {
              this.$bvModal.hide("error-modal-center");
            }, 3000);
          });
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public closePopup() {
    this.$bvModal.hide("error-modal-center");
  }
}
