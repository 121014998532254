export const AGENCY_DESCRIPTION_QUERY = `AgencyDescriptions {  
    agencyDescriptions {
      data {
        id
        attributes {
            heading
            description
        }
      }
    }
  }`;
