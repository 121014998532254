import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { AgenciesServiceListsData } from "./agency-service-lists.interface";
import { AGENCY_SERVICE_LIST_QUERY } from "@/graphql/agency-service-list-query";
import ChipSelect from "../chip-select/chip-select.vue";

@Component({
  components: {
    ChipSelect,
  },
})
export default class AgenciesServiceListComponent extends Vue {
  @Prop()
  public checkServiceValid!: boolean;
  @Prop()
  public services!: string;

  public agenciesServiceLists: AgenciesServiceListsData[] = [];
  public selectedValue: number[] = [];

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${AGENCY_SERVICE_LIST_QUERY}`,
      })
      .then((result) => {
        this.agenciesServiceLists = result.data.agencyServiceLists.data;
        if (this.services) {
          const refIdx = this.services.split(",");
          if (refIdx.length > 0) {
            const findService = this.agenciesServiceLists.filter(
              (data: AgenciesServiceListsData) =>
                refIdx.includes(data.attributes.ref_id.toString())
            );
            this.agenciesServiceLists = findService;
          }
        }
        const temp = JSON.parse(JSON.stringify(this.agenciesServiceLists));
        for (let index = 0; index < temp.length; index++) {
          temp[index] = temp[index].attributes;
        }
        this.agenciesServiceLists = temp;
      });
  }

  public values(event: any[]) {
    const getSelectedValue: any[] = event;
    this.selectedValue = getSelectedValue.map((data) => {
      return data.ref_id;
    });
    this.$emit("serviceId", this.selectedValue);
  }

  @Watch("checkServiceValid")
  public validation() {
    if (this.selectedValue.length) {
      this.$emit("isServiceValid", true);
    } else {
      this.$emit("isServiceValid", false);
    }
  }
}
