import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "app-loader" })
export default class AppLoaderComponent extends Vue {
  @Prop()
  public setLoadingCenterDiv!: boolean;
  @Prop()
  public type!: string;

  public isSmallSpinner = true;

  mounted() {
    if (this.type === "large") {
      this.isSmallSpinner = false;
    } else {
      this.isSmallSpinner = true;
    }
  }
}
