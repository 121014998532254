import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";

@Component({
  components: {
    AppLoaderComponent,
  },
})
export default class PreviewPopupComponent extends Vue {
  @Prop()
  public fileDetails!: any[];
  @Prop()
  public keyName!: string;
  @Prop()
  public typeOfView!: string;

  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public docType = ["pdf", "doc"];
  public isLoading = false;
  public slideIndex = 0;
  public totalLength = 0;

  public mounted() {
    let getFileType = "";
    this.$nextTick().then(() => {
      this.totalLength = this.fileDetails.length;
      this.fileDetails.length &&
        this.fileDetails.map((file: any) => {
          getFileType =
            file && file.uploadedFileName
              ? file.uploadedFileName.split(".")[1]
              : file.fileName
              ? file.fileName.split(".")[1]
              : file.type.split("/")[1];
          file.type = getFileType;
          if (this.docType.includes(getFileType)) {
            file.isDocument = true;
            file.isImage = false;
          } else {
            file.isDocument = false;
            file.isImage = true;
          }
        });
      this.isLoading = true;
      this.$bvModal.show(`preview-modal-center-` + this.keyName);
    });
  }

  public hide(event: any) {
    if (event) {
      this.$emit("hidePreviewPopup", true);
    }
  }

  public loadData(event: any) {
    this.isLoading = false;
  }

  public prev() {
    if (this.slideIndex <= this.totalLength - 1 && this.slideIndex != 0) {
      this.isLoading = true;
      this.slideIndex--;
    }
  }
  public next() {
    if (this.slideIndex < this.totalLength - 1) {
      this.isLoading = true;
      this.slideIndex++;
    }
  }

  public closePopup() {
    this.$bvModal.hide(`preview-modal-center-` + this.keyName);
    this.slideIndex = 0;
  }
}
