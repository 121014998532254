export const DOC_LIBRARY_QUERY = `DocLibrary { 
    docsLibraries (sort: "id:ASC"){
        data {
            id
            attributes{
                documentName
                keyName
                document {
                    data{
                        id
                        attributes{
                            name
                            hash
                            url
                            provider
                            mime
                        }
                    }
                }
            }
        }
    }
}`;
