export const MAIN_PAGE_CAROUSEL_QUERY = `Banners {  
    banners(sort: "id:ASC"){
        data {
          id
          attributes {
            carousel_images {
              description
              button {
                url
                label
              }
              image {
                data {
                  id
                  attributes {
                  name
                  alternativeText
                  formats
                  url
                  }
                }
              }
            }
          }
        }
      }
    }`;
